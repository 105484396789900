var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.storageSpaceMediaData)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.$t("StorageSpaces.media.data")))]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.$t("general.showStatus")))]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(_vm._s(_vm.$t("general.shareStatus")))]),_vm._m(0)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("general.image")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.code")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(_vm._s(_vm.$t("general.name")))])])]),_c('tbody',_vm._l((_vm.storageSpaceMediaData),function(storageSpaceMedia,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index + _vm.filterData.currentIndex))]),_c('td',[_c('img',{staticClass:"item-img-table no-border",attrs:{"src":_vm.fullPathFileFromServer(
                storageSpaceMedia.storageSpaceMediaFilePath,
                _vm.defaultImg
              ),"onerror":("this.src='" + _vm.defaultImg + "'")}})]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(storageSpaceMedia.fullCode)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(storageSpaceMedia.storageSpaceMediaNameCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( storageSpaceMedia.storageSpaceMediaFileShowStatusTypeNameCurrent ))+" ")]),_c('td',[(storageSpaceMedia.sharedMediaToken)?_c('span',[_vm._v(_vm._s(_vm.$t("yes")))]):_c('span',[_vm._v(_vm._s(_vm.$t("no")))])]),_c('td',{staticClass:"fmenu-item-container"},[_c('FloatingMenu',[(storageSpaceMedia.storageSpaceMediaFullLink)?_c('li',[_c('a',{attrs:{"target":"_blank","href":storageSpaceMedia.storageSpaceMediaFullLink,"title":_vm.$t('StorageSpaces.media.openSharedLink')}},[_c('img',{attrs:{"src":require("@/assets/images/link.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setStorageSpaceMediaData(storageSpaceMedia);
                  _vm.openBottomSheet('StorageSpaceMediaInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('QR.modelName')},on:{"click":function($event){_vm.setStorageSpaceMediaData(storageSpaceMedia);
                  _vm.openBottomSheet('StorageSpaceMediaQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.hasPrivilegeEdit && !storageSpaceMedia.sharedMediaToken)?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setStorageSpaceMediaData(storageSpaceMedia);
                  _vm.openBottomSheet('StorageSpaceMediaUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(
                _vm.hasPrivilegeFinaleDelete &&
                !storageSpaceMedia.sharedMediaToken
              )?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.StorageSpaceMediaDelete",modifiers:{"StorageSpaceMediaDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setStorageSpaceMediaData(storageSpaceMedia)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(
                _vm.hasPrivilegeChangeActivationType &&
                !storageSpaceMedia.sharedMediaToken
              )?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.StorageSpaceMediaChangeActivationType",modifiers:{"StorageSpaceMediaChangeActivationType":true}}],attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setStorageSpaceMediaData(storageSpaceMedia)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setStorageSpaceMediaData(storageSpaceMedia);
                  _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])],1)])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{attrs:{"rowspan":"2"}},[_c('i',{staticClass:"fas fa-sliders-h"})])}]

export { render, staticRenderFns }